@import "~antd/dist/antd.css";

.logo {
  height: 30px;
  margin: 15px 24px 15px 25px;
  cursor: default;
}
.faviconLogo {
  margin-top: 15px;
  margin-left: 15px;
  width: 45px;
}
